.modal-header {
    background-color: #f8f9fa;
}

.modal-title {
    font-weight: bold;
}

/* .list-group-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
} */

.list-group-item.success {
    background-color: #d4edda;
    color: #155724;
}

.list-group-item.danger {
    background-color: #f8d7da;
    color: #721c24;
}

.badge-success {
    background-color: #28a745;
}

.badge-danger {
    background-color: #dc3545;
}

.resposta {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    word-wrap: break-word;
    white-space: pre-wrap;
}