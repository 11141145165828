.custom-next-arrow {
    background-color: #adb5be; 
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-next-arrow:hover {
    background-color: #0056b3;
}

.arrow-right {
    display: inline-block;
    transform: rotate(-45deg);
}
